import React, { Component } from 'react'

export default class PhoneInput extends Component {
    render = () => {
        const { phone, onChange, disabled } = this.props


        return (
            <div className="mb-3">
                <label htmlFor="phone" className="form-label mb-1">Phone number</label>
                <input
                    type="tel"
                    name="phone"
                    id="phone"
                    aria-describedby="phone"
                    className="form-control"
                    placeholder="123-456-7890"
                    onChange={onChange}
                    minLength="12"
                    maxLength="12"
                    value={phone.replace(/([0-9]{3})([0-9]{1,3})/, "$1-$2")
                        .replace(/([0-9]{3})-([0-9]{3})([0-9]{1,4})/, "$1-$2-$3")}
                    pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                    disabled={disabled}
                />
            </div>
        )
    }
}
