import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { MDBDataTableV5 } from 'mdbreact';
import { updateUser } from "../actions";

const baseUrl = "https://us-central1-lazyposter.cloudfunctions.net"

class Dashboard extends Component {
    componentDidMount = async () => {
        const { user, updateUser } = this.props

        const headers = new Headers();
        headers.append("Accept", "application/json");
        headers.append("Content-Type", "application/json");

        const listings = await fetch(`${baseUrl}/getListings?jwt_token=${user.jwt_token}`, {
            method: "GET",
            headers: headers,
        }).then(res => res.json())

        updateUser({ ...user, listings })

        const { email, uid, subscription_active, expire_time, subscriptions, subscription_type, types, scheduling, jwt_token } = await fetch(`${baseUrl}/checkSubscription?jwt_token=${user.jwt_token}`, {
            method: "GET",
            headers: headers,
        }).then(res => res.json())

        updateUser({ ...user, listings, email, uid, subscription_active, expire_time, subscriptions, subscription_type, types, scheduling, jwt_token })

    }

    delete = (id, askConfirmation = true) => {
        if (askConfirmation) {
            const confirmation = window.confirm("Are you sure you want to delete this listing?")
            if (!confirmation) {
                return
            }
        }

        const { user, updateUser } = this.props
        const headers = new Headers();
        headers.append("Accept", "application/json");
        headers.append("Content-Type", "application/json");
        fetch(`${baseUrl}/deleteListing/${id}?jwt_token=${user.jwt_token}`, {
            method: "GET",
            headers: headers,
        })
            .then(response => {
                switch (response.status) {
                    case 400:
                        throw new Error("Invalid credentials!");
                    case 401:
                        throw new Error("Subscription expired! Please renew subscription.");
                    case 404:
                        throw new Error("Listing not found.");
                    case 200:
                        return response.json();
                    default:
                        throw new Error(`error connecting to server: ${response.status} ${response.statusText}`);
                }
            }).then(() => updateUser({ ...user, listings: user.listings.filter(l => l.id !== id) }))
            .catch(alert)
    }

    deleteSelected = async () => {
        const toDelete = []
        const checkboxes = document.querySelectorAll(".checkbox")
        for (let checkbox of checkboxes) {
            if (checkbox.checked) {
                toDelete.push(checkbox.id)
            }
        }

        const confirmation = window.confirm(`Are you sure you want to delete these ${toDelete.length} listings?`)
        if (confirmation) {
            for (const id of toDelete) {
                await this.delete(id, false)
            }
        }
    }

    getThumbUrl = url => {
        if (!url.includes("/lazyposter-images/")) {
            return url
        }
        const urlArray = url.split("/")
        urlArray.splice(urlArray.length - 1, 0, "thumbnails")
        return urlArray.join("/")
    }

    prepareColumns = listings => ({
        columns: [
            { label: "", field: "checkbox", sort: "disabled" },
            { label: "", field: "image", sort: "disabled" },
            { label: "Title", field: "title" },
            { label: "Price", field: "price" },
            { label: "Category", field: "category" },
            { label: "Campaign", field: "campaign" },
            { label: "Location", field: "location" },
            { label: "", field: "btn", sort: "disabled" },
        ],
        rows: listings.map(l => ({
            checkbox: <input key={"cb" + l.id} id={l.id} className="checkbox" type="checkbox" />,
            image: l.images?.length ? <img src={this.getThumbUrl(l.images[0])} key={"img" + l.id} height="45" alt="" /> : null,
            title: l.title || (l.propertyType ? `${l.propertyType} ${l.rentOrSale}` : `${l.make} ${l.model} ${l.year}`),
            price: l.price,
            category: l.subcategory3 || l.subcategory2 || l.subcategory1 || l.category || l.offerupSubcategory || l.offerupCategory || l.craigslistCategory || l.propertyType || l.vehicleType,
            campaign: l.campaign || "",
            location: l.location,
            description: l.description,
            btn: [
                <Link key={"ebtn" + l.id} to={`edit-listing/${l.id}`} target="_blank" rel="noopener noreferrer" className="btn btn-dark me-3"><i className="fas fa-edit"></i> Edit</Link>,
                <button key={"dbtn" + l.id} className="btn btn-danger" onClick={() => this.delete(l.id)} disabled={!this.props.user.subscription_active}><i className="fas fa-trash-alt"></i> Delete</button>
            ],
        }))
    })

    render = () => {
        const { user } = this.props
        const listings = user.listings || []
        const { columns, rows } = this.prepareColumns(listings)

        return (
            <div className="mt-3">
                <MDBDataTableV5 hover data={{ columns, rows }} entries={100} displayEntries={false} searchTop searchBottom={false} />
                <div className="col3 ms-4 mb-4">
                    <button className="btn btn-danger" onClick={this.deleteSelected} disabled={!user.subscription_active}><i className="fas fa-trash-alt"></i> Delete Selected</button>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    user: state.user,
});

const mapDispatchToProps = dispatch => ({
    updateUser: (user) => dispatch(updateUser(user)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Dashboard);