import React, { Component } from 'react'

export default class Checkbox extends Component {
    render = () => {
        const { display = true, name, label, options, checked, labels, onChange, required, disabled } = this.props


        return !!display &&
            (<div className="mb-3">
                {label ? <label htmlFor={name} className="form-label mb-1">{label}</label> : <></>}
                {options.map((option, i) => (
                    <div className="form-check" key={option}>
                        <input className="form-check-input" type="checkbox" name={name} id={option} onChange={onChange} checked={checked.includes(option)} required={required} disabled={disabled} />
                        <label className="form-check-label" htmlFor={option}>
                            {labels ? labels[i] : option}
                        </label>
                    </div>
                ))}
            </div>)
    }
}
