import React, { Component } from 'react'

export default class Select extends Component {
    render = () => {
        const { display = true, name, label, defaultValue, options, labels, keys, onChange, required, disabled } = this.props


        return !!display &&
            (<div className="mb-3">
                <label htmlFor={name} className="form-label mb-1">{label}</label>
                <select name={name} value={defaultValue} className="form-select" onChange={onChange} required={required} disabled={disabled} aria-describedby={name}>
                    <option disabled={true} value="">none</option>
                    {options.map((option, i) => <option value={option} key={keys ? keys[i] : option}>{labels ? labels[i] : option}</option>)}
                </select>
                {required && <div className="invalid-feedback">required</div>}
            </div>)
    }
}
