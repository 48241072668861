import React, { Component } from 'react'

export default class FbGroups extends Component {
    render = () => {
        const { display = true, groups, userGroups, onChange, disabled } = this.props


        return !!display &&
            (<div className="mb-3">
                <label htmlFor="groups" className="form-label mb-1">List in Facebook Group(s): Copy/paste Exact Title (case sensitive)</label>
                {groups.map((group, i) =>
                    <div key={`group-${i}`}>
                        <input list="userGroups" type="text" name="groups" defaultValue={group} className="form-control mb-1" onChange={e => onChange(e.target.value, i)} id="group" aria-describedby="group" disabled={disabled} />
                        <datalist id="userGroups">
                            {
                                userGroups && Object.keys(userGroups).length ? Object.keys(userGroups).map(g => (<option key={g} value={g} />)) : null
                            }
                        </datalist>
                    </div>
                )}
            </div>)
    }
}
